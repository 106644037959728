/* .video-wrapper {
    position: absolute;
    top: 0;
    overflow: hidden;
} */
#gif-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background-color: #000;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	z-index: -1;
}

#gif-logo {
	position: fixed;
	top: 0.5rem;
	right: 0.5rem;
	border-radius: 30px;
	padding: 0 1rem;
	background: #000;
}

#gif-logo img {
	max-width: 150px;
}

/* #new-gif {
  padding: 0.75rem 2.5rem;
  font-weight: 600;
  background: #424242;
  z-index: 10;
  color: #fff;
  border-radius: 30px;
  border: 0;
  font-size: 14px;
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  transition: 'background' 0.15s ease;
  cursor: pointer;
} */
#backgroundImg {
	width: 200px;
}

#new-gif:hover {
	background: #626262;
}

.remove {
	color: white;
}

.react-resizable-handle {
	color: white;
	border-right: 2px solid rgb(255, 255, 255, 0.42);
	border-bottom: 2px solid rgb(255, 255, 255, 0.42);
}

.react-resizable-handle::after {
	border-right: 2px solid rgb(255, 255, 255, 0.72) !important;
	border-bottom: 2px solid rgb(255, 255, 255, 0.72) !important;
}

.buttonHolder {
	background: black;
	box-shadow: white 0px 1px 6px 1px;
	/* width: 100%; */

	text-align: center;
	position: fixed;
	top: 10px;
	width: 40px;
	height: 40px;
	/* left: -420px; */
	/* left: 50%; */
	/* margin: 10px; */
	padding: 0px;
	opacity: 0.35;
	/* right: 50%; */
}

#actionButtons button {
	opacity: 0;
}

#actionButtons input {
	opacity: 0;
}

#actionButtons:hover {
	opacity: 1;
	top: 10px;
	left: 10px;
	width: auto;
	height: auto;
	padding: 20px;
	/* width: 100%; */
}

#commandBox {
	margin: 5px;
	width: 300px;
}

#commandContainer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	/* width: 100%;
    height: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #0000007d;
	z-index: 100000000;
}

.commandButton {
	margin: 10px;
	padding: 10px;
	/* flex: 1; */
}

.rounded {
	/* border: none !important; */
	/* border: #ffffff96 1px solid; */
	background-image: -webkit-gradient(linear, left bottom, left top, from(#cfd9df), to(#e2ebf0)) !important;
	background-image: linear-gradient(to top, #cfd9df 0, #e2ebf0 100%) !important;
	border-radius: 15% !important;
	box-shadow: 0 2px 4px #888888;

	transition: color 0.3s, background-image 0.5s, ease-in-out !important;
}

.commandContainer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	/* width: 100%;
    height: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #0000007d;
	z-index: 100000000;
	overflow-y: scroll;
}

.homeBox {
	/* flex: 1;
        height: 80%; */
	background: white;
	padding: 20px;
}

.createBox {
	/* flex: 1;
        height: 80%; */
	background: black;
	padding: 20px;
	margin: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* align-items: center; */
}

#createBox {
	flex: 1;
	width: 800px;
}

.commandContainer2 {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	/* width: 100%;
        height: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #0000007d;
	z-index: 100000000;
}

#actionButtons:hover button {
	color: white;
	opacity: 1;
}

#actionButtons:hover input {
	/* color: white; */
	opacity: 1;
}

#actionButtons:hover span {

	opacity: 0;
}


.buttonHolder button {
	background: transparent;
	color: black;
	margin: 5px;
	cursor: pointer;
}

.buttonHolder button:hover {
	color: pink;
	border-color: pink;
	/* text-decoration: blink; */
}

.numberBall {
	font: 32px Arial, sans-serif;
	width: 2em;
	height: 2em;
	box-sizing: initial;
	background: #fff;
	border: 0.1em solid #666;
	color: #666;
	text-align: center;
	border-radius: 50%;
	line-height: 2em;
	box-sizing: content-box;
	font-size: xx-large;
	opacity: 0.4;
	position: fixed;
	bottom: 0;
	right: 0;
}

.numberContainer {
	width: 100%;
	position: absolute;
	inset: 0px;
	z-index: 100;
	background: rgba(0, 0, 0, 0.13);
	display: flex;
	align-items: center;
	justify-content: center;
}

.detail-div {
	position: fixed;
	left: 10%;
	top: 0;
	right: 10%;
	bottom: 0;
	background-color: #000000d4;
	z-index: 1;
	box-shadow: black 6px 11px 28px 4px;
}

.help-div {
	position: fixed;
	left: 10%;
	top: 0;
	right: 10%;
	bottom: 0;
	background-color: #000000d4;
	z-index: 1;
	box-shadow: black 6px 11px 28px 4px;
}

.help {
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.help ul {
	width: 100%;
}

.help ul li {
	background-color: transparent;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.highlight {
	color: cyan;
	font-weight: bold;
	text-transform: uppercase;
}

.highlight i {
	color: hotpink;
	font-style: unset;
	text-transform: uppercase;
	font-size: small;
}

.help h3 {
	margin-bottom: 20px;
}

.boardsList {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}

.boardEntry {
	margin: 10px;
	/* background: white; */
	flex: 1;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.boardEntry a {
	margin: 10px;
}

.createBoard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* background: white; */
}

.detail-form {
	position: absolute;
	overflow: scroll;
	top: 0;
	width: 90%;
	height: 90%;
	margin: auto;
	/* background-color: #e4f0f7c4; */
	z-index: 1;
	/* box-shadow: white 4px 4px 17px -1px; */
	/* border: cornsilk; */
	bottom: 0;
	left: 0;
	right: 0;
	/* border-width: thin;
    border-style: outset; */

}

.detail-group {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 25px;
}

.detail-group form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	position: relative;
	/* top: 0;
    bottom: 0;
    left: 0; */
	background: #ffffffd4;
	/* right: 0; */
	padding: 20px;
	margin: 30px;
	box-shadow: black 2px 2px 4px -2px;
}

#root {
	/* -moz-transform: scale(3);
       -webkit-transform: scale(3);
       -ms-transform: scale(3);
           transform: scale(3); */
	transform: all 2.5s ease;
}

.block {
	/* -webkit-transition: all 1.5s ease;
    -moz-transition: all 1.5s ease;
    -o-transition: all 1.5s ease;
    transition: all 1.5s ease; */
	/* -moz-transform: scale(3);
       -webkit-transform: scale(3);
       -ms-transform: scale(3);
           transform: scale(3); */
	/* transform: all 1.5s ease; */
}

.fullscreen {
	z-index: 9999 !important;
	/* width: 100vw !important;
    height: 100vh !important; */
	position: fixed !important;
	/* top: 0 !important;
    left: 0 !important; */
	/* transform: none !important; */

}


.react-grid-item:hover {
	border-width: thin;
	border-color: #f0f1ffe3;
	border-style: groove;

}