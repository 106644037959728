@import url(https://fonts.googleapis.com/css?family=Open+Sans:400,700|Work+Sans:700);
.focus-div {
	position: absolute;
	/* left: 10%; */
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	/* background-color: #000000d4; */
	z-index: 1;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.ItemsList {
	padding: 25px;
}

.ItemDiv p {
	margin-top: 1rem;
}

.App {
	/* width: 50%; */
	margin: 0 auto;
	/* position: absolute;
    left: 0;
    right: 0;
    top: 20vh; */
	background: #000000b3;
	padding: 20px;
}

.App h2 {
	/* font: 700 35px 'Work Sans'; */
	margin-bottom: 0px;
	color: white;
}

.App .stats,
.App .stats-percent {
	display: inline-block;
	font: 400 16px 'Open Sans';
	margin-right: 20px;
	color: #888;
	margin-bottom: 20px;
}

.App .stats {
	float: left;
}

.App .stats-percent {
	float: right;
}

.App .stats span,
.App .stats-percent span {
	font: 700 18px 'Work Sans';
	color: #00CDEA;
}

input,
input:focus {
	transition: .4s all;
	padding: 18px 20px;
	border-radius: 4px;
	font: 400 16px 'Open Sans';
	background: #F8F8F8;
	border: none;
	color: #666;
	outline: 0;
	width: 93%;
}

::-webkit-input-placeholder {
	color: #AAA;
}

ul {
	list-style: none;
	border-radius: 2px;
	padding: 0;
	box-shadow: 0 0 20px #EEE;
}

ul li {
	transition: .3s all;
	padding: 10px 25px;
	background: #FFF;
	border-bottom: 2px solid transparent;
}

ul>li>.ItemDiv,
ul>li>.TaskOptions {
	transition: .3s all;
	display: inline-block;
}

ul li .ItemDiv {
	min-width: 85%;
	max-width: 85%;
}

ul li .TaskOptions {
	width: 15%;
	text-align: right;
}

ul li:hover {
	background: #F5F5F5;
	cursor: pointer;
}

ul li:hover .ItemDiv p {
	color: #444;
}

ul li:hover .TaskOptions button {
	background: #FFF;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Styling for the List Div */
ul>li>.ItemDiv>p {
	font: 400 16px 'Open Sans';
	color: #5D6569;
}

ul>li>.TaskOptions {
	float: right;
	position: relative;
	top: 12px;
}

ul>li>.TaskOptions>button,
ul>li>.TaskOptions>button:focus {
	-webkit-transition: all .3s;
	border: none;
	background: #F5F5F5;
	box-shadow: none;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 0;
}

ul>li>.TaskOptions>button:hover {
	background: #FFF;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	cursor: pointer;
}

ul>li>.TaskOptions>button>span {
	color: #AAA;
}

/* When Marked As Done/Complete */
ul>li.done {
	border-color: rgba(255, 255, 255, 0.1);
	background: #00CDEA;
}

ul>li.done>.ItemDiv>p {
	text-decoration: line-through;
	color: #FFF;
}

ul>li.done>.TaskOptions>button {
	background: rgba(255, 255, 255, 0.3);
}

ul>li.done>.TaskOptions>button>span {
	color: #FFF;
}

/* When Marked As Done/Complete is Hovered */
ul>li.done:hover {
	background: #02C5E0;
	padding-left: 30px;
}

ul>li.done:hover>.TaskOptions>button {
	background: #FFF;
	box-shadow: none;
}

ul>li.done:hover>.TaskOptions>button>span {
	color: #00CDEA;
}
/* .video-wrapper {
    position: absolute;
    top: 0;
    overflow: hidden;
} */
#gif-wrap {
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: auto;
	background-color: #000;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
	z-index: -1;
}

#gif-logo {
	position: fixed;
	top: 0.5rem;
	right: 0.5rem;
	border-radius: 30px;
	padding: 0 1rem;
	background: #000;
}

#gif-logo img {
	max-width: 150px;
}

/* #new-gif {
  padding: 0.75rem 2.5rem;
  font-weight: 600;
  background: #424242;
  z-index: 10;
  color: #fff;
  border-radius: 30px;
  border: 0;
  font-size: 14px;
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  transition: 'background' 0.15s ease;
  cursor: pointer;
} */
#backgroundImg {
	width: 200px;
}

#new-gif:hover {
	background: #626262;
}

.remove {
	color: white;
}

.react-resizable-handle {
	color: white;
	border-right: 2px solid rgb(255, 255, 255, 0.42);
	border-bottom: 2px solid rgb(255, 255, 255, 0.42);
}

.react-resizable-handle::after {
	border-right: 2px solid rgb(255, 255, 255, 0.72) !important;
	border-bottom: 2px solid rgb(255, 255, 255, 0.72) !important;
}

.buttonHolder {
	background: black;
	box-shadow: white 0px 1px 6px 1px;
	/* width: 100%; */

	text-align: center;
	position: fixed;
	top: 10px;
	width: 40px;
	height: 40px;
	/* left: -420px; */
	/* left: 50%; */
	/* margin: 10px; */
	padding: 0px;
	opacity: 0.35;
	/* right: 50%; */
}

#actionButtons button {
	opacity: 0;
}

#actionButtons input {
	opacity: 0;
}

#actionButtons:hover {
	opacity: 1;
	top: 10px;
	left: 10px;
	width: auto;
	height: auto;
	padding: 20px;
	/* width: 100%; */
}

#commandBox {
	margin: 5px;
	width: 300px;
}

#commandContainer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	/* width: 100%;
    height: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #0000007d;
	z-index: 100000000;
}

.commandButton {
	margin: 10px;
	padding: 10px;
	/* flex: 1; */
}

.rounded {
	/* border: none !important; */
	/* border: #ffffff96 1px solid; */
	background-image: linear-gradient(to top, #cfd9df 0, #e2ebf0 100%) !important;
	border-radius: 15% !important;
	box-shadow: 0 2px 4px #888888;

	transition: color 0.3s, background-image 0.5s, ease-in-out !important;
}

.commandContainer {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	/* width: 100%;
    height: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #0000007d;
	z-index: 100000000;
	overflow-y: scroll;
}

.homeBox {
	/* flex: 1;
        height: 80%; */
	background: white;
	padding: 20px;
}

.createBox {
	/* flex: 1;
        height: 80%; */
	background: black;
	padding: 20px;
	margin: 10px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	/* align-items: center; */
}

#createBox {
	flex: 1 1;
	width: 800px;
}

.commandContainer2 {
	position: fixed;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	/* width: 100%;
        height: 100%; */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	background: #0000007d;
	z-index: 100000000;
}

#actionButtons:hover button {
	color: white;
	opacity: 1;
}

#actionButtons:hover input {
	/* color: white; */
	opacity: 1;
}

#actionButtons:hover span {

	opacity: 0;
}


.buttonHolder button {
	background: transparent;
	color: black;
	margin: 5px;
	cursor: pointer;
}

.buttonHolder button:hover {
	color: pink;
	border-color: pink;
	/* text-decoration: blink; */
}

.numberBall {
	font: 32px Arial, sans-serif;
	width: 2em;
	height: 2em;
	box-sizing: initial;
	background: #fff;
	border: 0.1em solid #666;
	color: #666;
	text-align: center;
	border-radius: 50%;
	line-height: 2em;
	box-sizing: content-box;
	font-size: xx-large;
	opacity: 0.4;
	position: fixed;
	bottom: 0;
	right: 0;
}

.numberContainer {
	width: 100%;
	position: absolute;
	inset: 0px;
	z-index: 100;
	background: rgba(0, 0, 0, 0.13);
	display: flex;
	align-items: center;
	justify-content: center;
}

.detail-div {
	position: fixed;
	left: 10%;
	top: 0;
	right: 10%;
	bottom: 0;
	background-color: #000000d4;
	z-index: 1;
	box-shadow: black 6px 11px 28px 4px;
}

.help-div {
	position: fixed;
	left: 10%;
	top: 0;
	right: 10%;
	bottom: 0;
	background-color: #000000d4;
	z-index: 1;
	box-shadow: black 6px 11px 28px 4px;
}

.help {
	color: white;
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}

.help ul {
	width: 100%;
}

.help ul li {
	background-color: transparent;
	justify-content: space-between;
	align-items: center;
	display: flex;
}

.highlight {
	color: cyan;
	font-weight: bold;
	text-transform: uppercase;
}

.highlight i {
	color: hotpink;
	font-style: unset;
	text-transform: uppercase;
	font-size: small;
}

.help h3 {
	margin-bottom: 20px;
}

.boardsList {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

}

.boardEntry {
	margin: 10px;
	/* background: white; */
	flex: 1 1;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.boardEntry a {
	margin: 10px;
}

.createBoard {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	/* background: white; */
}

.detail-form {
	position: absolute;
	overflow: scroll;
	top: 0;
	width: 90%;
	height: 90%;
	margin: auto;
	/* background-color: #e4f0f7c4; */
	z-index: 1;
	/* box-shadow: white 4px 4px 17px -1px; */
	/* border: cornsilk; */
	bottom: 0;
	left: 0;
	right: 0;
	/* border-width: thin;
    border-style: outset; */

}

.detail-group {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 25px;
}

.detail-group form {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: stretch;
	position: relative;
	/* top: 0;
    bottom: 0;
    left: 0; */
	background: #ffffffd4;
	/* right: 0; */
	padding: 20px;
	margin: 30px;
	box-shadow: black 2px 2px 4px -2px;
}

#root {
	/* -moz-transform: scale(3);
       -webkit-transform: scale(3);
       -ms-transform: scale(3);
           transform: scale(3); */
	transform: all 2.5s ease;
}

.block {
	/* -webkit-transition: all 1.5s ease;
    -moz-transition: all 1.5s ease;
    -o-transition: all 1.5s ease;
    transition: all 1.5s ease; */
	/* -moz-transform: scale(3);
       -webkit-transform: scale(3);
       -ms-transform: scale(3);
           transform: scale(3); */
	/* transform: all 1.5s ease; */
}

.fullscreen {
	z-index: 9999 !important;
	/* width: 100vw !important;
    height: 100vh !important; */
	position: fixed !important;
	/* top: 0 !important;
    left: 0 !important; */
	/* transform: none !important; */

}


.react-grid-item:hover {
	border-width: thin;
	border-color: #f0f1ffe3;
	border-style: groove;

}
.App {
	text-align: center;
}

button {
	outline: none !important;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.rendered-react-keyed-file-browser {
	background: white;
	padding: 20px;
	width: 100%;
	margin: 20px;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.remove {
	color: white;
	z-index: 1000;
	/* flex: 1; */
	text-align: center;
	background-color: #282c34;
	border-radius: 5px;
	border: white 1px solid;
	padding: 5px;
}

.edit {
	color: white;
	z-index: 100;
	/* flex: 1; */
	text-align: center;
	background-color: #282c34;
	border-radius: 5px;
	border: white 1px solid;
	padding: 5px;
}

.top-right-corner-icons {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;


	/* width: 100px; */
}

.initially-hidden {
	opacity: 0;
}

.initially-hidden:hover {
	opacity: 1;
}

.top-left-corner-icons {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 100px; */

}

.react-resizable-handle {
	z-index: 100;
}

.buttonHolder {
	/* width: 100%; */
	text-align: center;
}

.fade {
	opacity: .2;
}

.normal {
	opacity: 1;
}

/* iframe {
	z-index: 0 !important;
} */

