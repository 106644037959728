@import url('https://fonts.googleapis.com/css?family=Open+Sans:400,700|Work+Sans:700');

.focus-div {
	position: absolute;
	/* left: 10%; */
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	/* background-color: #000000d4; */
	z-index: 1;
}

body {
	margin: 0;
	font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;

}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

.ItemsList {
	padding: 25px;
}

.ItemDiv p {
	margin-top: 1rem;
}

.App {
	/* width: 50%; */
	margin: 0 auto;
	/* position: absolute;
    left: 0;
    right: 0;
    top: 20vh; */
	background: #000000b3;
	padding: 20px;
}

.App h2 {
	/* font: 700 35px 'Work Sans'; */
	margin-bottom: 0px;
	color: white;
}

.App .stats,
.App .stats-percent {
	display: inline-block;
	font: 400 16px 'Open Sans';
	margin-right: 20px;
	color: #888;
	margin-bottom: 20px;
}

.App .stats {
	float: left;
}

.App .stats-percent {
	float: right;
}

.App .stats span,
.App .stats-percent span {
	font: 700 18px 'Work Sans';
	color: #00CDEA;
}

input,
input:focus {
	transition: .4s all;
	padding: 18px 20px;
	border-radius: 4px;
	font: 400 16px 'Open Sans';
	background: #F8F8F8;
	border: none;
	color: #666;
	outline: 0;
	width: 93%;
}

::-webkit-input-placeholder {
	color: #AAA;
}

ul {
	list-style: none;
	border-radius: 2px;
	padding: 0;
	box-shadow: 0 0 20px #EEE;
}

ul li {
	transition: .3s all;
	padding: 10px 25px;
	background: #FFF;
	border-bottom: 2px solid transparent;
}

ul>li>.ItemDiv,
ul>li>.TaskOptions {
	transition: .3s all;
	display: inline-block;
}

ul li .ItemDiv {
	min-width: 85%;
	max-width: 85%;
}

ul li .TaskOptions {
	width: 15%;
	text-align: right;
}

ul li:hover {
	background: #F5F5F5;
	cursor: pointer;
}

ul li:hover .ItemDiv p {
	color: #444;
}

ul li:hover .TaskOptions button {
	background: #FFF;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

/* Styling for the List Div */
ul>li>.ItemDiv>p {
	font: 400 16px 'Open Sans';
	color: #5D6569;
}

ul>li>.TaskOptions {
	float: right;
	position: relative;
	top: 12px;
}

ul>li>.TaskOptions>button,
ul>li>.TaskOptions>button:focus {
	-webkit-transition: all .3s;
	border: none;
	background: #F5F5F5;
	box-shadow: none;
	height: 30px;
	width: 30px;
	border-radius: 50%;
	outline: 0;
}

ul>li>.TaskOptions>button:hover {
	background: #FFF;
	box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
	cursor: pointer;
}

ul>li>.TaskOptions>button>span {
	color: #AAA;
}

/* When Marked As Done/Complete */
ul>li.done {
	border-color: rgba(255, 255, 255, 0.1);
	background: #00CDEA;
}

ul>li.done>.ItemDiv>p {
	text-decoration: line-through;
	color: #FFF;
}

ul>li.done>.TaskOptions>button {
	background: rgba(255, 255, 255, 0.3);
}

ul>li.done>.TaskOptions>button>span {
	color: #FFF;
}

/* When Marked As Done/Complete is Hovered */
ul>li.done:hover {
	background: #02C5E0;
	padding-left: 30px;
}

ul>li.done:hover>.TaskOptions>button {
	background: #FFF;
	box-shadow: none;
}

ul>li.done:hover>.TaskOptions>button>span {
	color: #00CDEA;
}