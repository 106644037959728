.App {
	text-align: center;
}

button {
	outline: none !important;
}

.App-logo {
	animation: App-logo-spin infinite 20s linear;
	height: 40vmin;
	pointer-events: none;
}

.rendered-react-keyed-file-browser {
	background: white;
	padding: 20px;
	width: 100%;
	margin: 20px;
}

.App-header {
	background-color: #282c34;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

@keyframes App-logo-spin {
	from {
		transform: rotate(0deg);
	}

	to {
		transform: rotate(360deg);
	}
}

.remove {
	color: white;
	z-index: 1000;
	/* flex: 1; */
	text-align: center;
	background-color: #282c34;
	border-radius: 5px;
	border: white 1px solid;
	padding: 5px;
}

.edit {
	color: white;
	z-index: 100;
	/* flex: 1; */
	text-align: center;
	background-color: #282c34;
	border-radius: 5px;
	border: white 1px solid;
	padding: 5px;
}

.top-right-corner-icons {
	position: absolute;
	top: 10px;
	right: 10px;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;


	/* width: 100px; */
}

.initially-hidden {
	opacity: 0;
}

.initially-hidden:hover {
	opacity: 1;
}

.top-left-corner-icons {
	position: absolute;
	top: 10px;
	left: 10px;
	z-index: 100;
	display: flex;
	justify-content: center;
	align-items: center;
	/* width: 100px; */

}

.react-resizable-handle {
	z-index: 100;
}

.buttonHolder {
	/* width: 100%; */
	text-align: center;
}

.fade {
	opacity: .2;
}

.normal {
	opacity: 1;
}

/* iframe {
	z-index: 0 !important;
} */